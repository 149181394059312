<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="14" md="7">
        <b-card>
        <div slot="header"><i class="fas fa-gamepad"></i> Game Alerts</div>
        <div>
          <b-row class="mr-auto">
                      <b-col cols="auto" class="mr-auto py-1">
                          <h5 style="font-weight: bold;">Notifications for free games </h5>
                          <p>This will send notifications to the selected channel whenever a game goes free of cost on selected platforms.</p>

                      </b-col>
                      <b-col cols="auto" class="p-1">
                        <c-switch
                          id="game_alerts_toggle"
                          color="info"
                          label
                          variant="pill"
                          v-bind="labelIcon"
                          v-model="alerts_enabled"
                        />
                      </b-col>
                    </b-row>
           <br>
           <b-row class="mr-auto">
                      <b-col cols="auto" class="mr-auto py-1">
                          <h5 style="font-weight: bold;">Notifications for discounts and offers on games</h5>
                          This will send notifications to the selected channel whenever a game or a DLC goes on a discounted sale.
                      </b-col>
                      <b-col cols="auto" class="p-1">
                        <c-switch
                          id="deal_alerts_toggle"
                          color="info"
                          label
                          variant="pill"
                          v-bind="labelIcon"
                          v-model="deals_enabled"
                        />
                      </b-col>
                    </b-row>

          </div>
          <br>
          <label for="discord_channel_select" class="teenytinytitle">Notifications Channel</label>
          <multiselect
            v-model="alert_channel"
            id="discord_channel_select"
            track-by="id"
            label="name"
            placeholder="Please select a channel"
            open-direction="bottom"
            :searchable="true"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            :options="groupedChannels"
            :allow-empty="true"
            group-values="channels"
            group-label="category"
            :group-select="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <font-awesome-icon style="color:#72767d" icon="hashtag" />
                <span class="option__title ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <font-awesome-icon
                    style="color:#72767d"
                    :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                  />
                  {{ props.option.name || props.option.$groupLabel.name }}
                </span>
              </div>
            </template>
          </multiselect>

          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings">Save</b-button>
          </div>
        </b-card>


      </b-col>
      <b-col sm="12" md="6">
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import EmbedBuilder from "@/views/oc/EmbedBuilder";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

const reducer = (accumulator, currentValue, currentIndex) =>
  accumulator + (currentValue << currentIndex);

Vue.use(VueNotifications, toast_options);

export default {
  name: "Logging",
  components: {
    cSwitch,
    EmbedBuilder,
    Multiselect
  },
  data: function() {
    return {
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      channels: [],
      alerts_enabled: false,
      deals_enabled: false,
      alert_channel: null,
      discount_threshold: 0,
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Setting applied"
    },
    showFailMsg:{
    type: VueNotifications.types.error,
      title: "Uh oh!",
      message: "Failed to update config, try again in some time!!",
    },
    showRateLimitError:{
    type: VueNotifications.types.error,
      title: "You have been rate-limited!",
      message: "Try again after 10s",
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/gaming")
      .then(r => {
        this.channels = r.data.channels;
        this.alerts_enabled = r.data.alerts_enabled;
        this.deals_enabled = r.data.deals_enabled;
        this.discount_threshold = r.data.discount_threshold;
        this.alert_channel = this.channels.find(
          x => x.id == r.data.alert_channel
        ) || { name: "No channel selected", id: null };
      });
  },
  methods: {
    put_settings() {
      this.axios
        .post("/api/v1/servers/" + this.$route.params.guild_id + "/gaming", {
          alert_channel: this.alert_channel ? this.alert_channel.id : null,
          alerts_enabled: this.alerts_enabled,
          deals_enabled: this.deals_enabled,
        })
        .then(resp=>{
        if(resp.status==200)
        {
        if(resp.data.success === true)
        {this.showSuccessMsg();}
        else{
        this.showFailMsg();}
        }
        else{this.showFailMsg()}
        }).catch(error => {
              if(error.response.status==429)
                  {
                  this.showRateLimitError();
                  }
          });
    },
    handleKeyDown(event) {
    this.handleNonDecimalInput(event);
    const currentValue = parseInt(event.target.value + event.key);

    if (isNaN(currentValue) ||currentValue > 90) {
       event.preventDefault();
    }

},
handleBlur(event) {
    const currentValue = parseInt(event.target.value);

    if (currentValue < 10) {
       this.discount_threshold = 10;
    }

    if (isNaN(currentValue)){
    this.discount_threshold = 50;
    }

},
  },
  computed: {
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    }
  },
  created() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/gaming")
      .then(r => {
        this.channels = r.data.channels;
        this.alerts_enabled = r.data.alerts_enabled;
        this.deals_enabled = r.data.deals_enabled;
        this.alert_channel = this.channels.find(
          x => x.id == r.data.alert_channel
        ) || { name: "No channel selected", id: null };
        this.$Progress.finish();
      });
  }
};
</script>
