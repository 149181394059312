<template>
  <div class="animated fadeIn">

      <b-col sm="12">
        <b-card header-tag="header" class="card-container">
        <b-tabs  v-model="selectedTab"  @input="onTabChange">

          <b-tab title="Recent" :key="0">
            <div>
              <b-row>
              <b-row class="card-container">
              <b-col v-if="showFeaturedCard" v-for="(card, ind) in featuredCards" :key="'featured-' + ind" cols="12" sm="6" md="5" lg="3" class="mb-4">
                    <b-card class="custom-card featured-card">
                        <div slot="header"><i class="icon-dollars"></i>{{card.title}}</div>

                        <b-card-body class="custom-card-body">
                          <b-img
                                :src="card.banner"
                                alt="Card image"
                                class="custom-card-img"
                                @error="event => event.target.src = 'https://i.ibb.co/DCzgcSt/Carl-Deals.png'"
                              />
                          <div style="text-align: center;">
                           <i>{{ card.shop_details.name }}</i>
                           <br>
                           <b> Available in {{ card.availability.join(', ') }} </b>
                           <br>
                           <br>
                           <b> Uncensored version</b>
                          </div>

                        </b-card-body>

                        <div class="custom-card-footer" style="margin-top: 8%;">
                            <b-button
                                    class="featured-button"
                                    target="_blank"
                                    rel="noreferrer noopener"

                                    style="width: 100%;"
                                    size="lg"
                                    :href="card.url"
                                    v-on:click="handleFeaturedCardButtonClick"
                                    >{{  'Play Now!' }} </b-button
                                  >
                        </div>
                    </b-card>
                </b-col>
                 <b-col v-for="(card, ind) in freeGamesCard" :key="ind" cols="12" sm="6" md="4" lg="3" class="mb-4">
                    <b-card class="custom-card">
                        <div slot="header"><i class="icon-dollars"></i>{{card.title}}</div>

                        <b-card-body class="custom-card-body">
                          <b-img
                                :src="card.banner"
                                alt="Card image"
                                class="custom-card-img"
                                @error="event => event.target.src = 'https://i.ibb.co/DCzgcSt/Carl-Deals.png'"
                              />
                          <div style="text-align: center;">
                           <i>{{ JSON.parse(card.shop_details).name }}</i>
                           <br>
                           <s><i>${{card.regular_price}}</i></s>
                           <b> ${{card.current_price}} </b>
                           <b>(-{{card.discount}}%)</b>
                           <br>
                           <b v-if ="card.voucher">Promo Code:</b> <pre id=ind style="color: white; display: inline;">{{ card.voucher }}</pre>
                           <br>
                           <small>{{ new Date(card.published_timestamp).toLocaleDateString() }}</small>
                          </div>
                        </b-card-body>

                        <div class="custom-card-footer">
                            <b-button
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    :variant="(card.expiry && card.expiry.trim() !== '') ? 'success' : 'success'"
                                    style="width: 100%;"
                                    size="lg"
                                    :href="card.url"
                                    v-on:click="handleGamesDealsButtonClick"
                                    >{{ (card.expiry && card.expiry.trim() !== '') ? (new Date(card.expiry).getTime() < new Date().getTime() ? 'Claim!' : 'Claim!') : 'Claim!' }} </b-button
                                  >
                        </div>
                    </b-card>
                </b-col>
              </b-row>
            </b-row>
            <div>
            <pagination
            v-model="currentPage"
      :records="totalGameCards"
      :per-page="pageSize"
    ></pagination></div>

            </div>
          </b-tab>
          <b-tab v-if="ShowDealsTab" title="Deals" :key="1">
            <div>
               <b-row>
              <b-row class="card-container">
                <b-col v-if="showFeaturedCard" v-for="(card, ind) in featuredCards" :key="'featured-' + ind" cols="12" sm="6" md="5" lg="3" class="mb-4">
                    <b-card class="custom-card featured-card">
                        <div slot="header"><i class="icon-dollars"></i>{{card.title}}</div>

                        <b-card-body class="custom-card-body">
                          <b-img
                                :src="card.banner"
                                alt="Card image"
                                class="custom-card-img"
                                @error="event => event.target.src = 'https://i.ibb.co/DCzgcSt/Carl-Deals.png'"
                              />
                          <div style="text-align: center;">
                           <i>{{ card.shop_details.name }}</i>
                           <br>
                           <b> Available in {{ card.availability.join(', ') }} </b>

                           <br>
                           <br>
                           <b> Uncensored version</b>
                          </div>

                        </b-card-body>

                        <div class="custom-card-footer" style="margin-top: 8%;">
                            <b-button
                                    class="featured-button"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    style="width: 100%;"
                                    size="lg"
                                    :href="card.url"
                                    v-on:click="handleFeaturedCardButtonClick"
                                    >{{  'Play Now!' }} </b-button
                                  >
                        </div>
                    </b-card>
                </b-col>
                 <b-col v-for="(card, ind) in freeDealsCard" :key="ind" cols="12" sm="6" md="4" lg="3" class="mb-4">
                    <b-card class="custom-card">
                        <div slot="header"><i class="icon-dollars"></i>{{card.title}}</div>

                        <b-card-body class="custom-card-body">
                          <b-img
                                :src="card.banner"
                                alt="Card image"
                                class="custom-card-img"
                                @error="event => event.target.src = 'https://i.ibb.co/DCzgcSt/Carl-Deals.png'"
                              />
                          <div style="text-align: center;">
                           <i>{{ JSON.parse(card.shop_details).name }}</i>
                           <br>
                           <s><i>${{card.regular_price}}</i></s>
                           <b> ${{card.current_price}} </b>
                           <b>(-{{card.discount}}%)</b>
                           <br>
                           <b v-if ="card.voucher">Promo Code:</b> <pre id=ind style="color: white; display: inline;">{{ card.voucher }}</pre>
                           <br>
                           <small>{{ new Date(card.published_timestamp).toLocaleDateString() }}</small>
                          </div>
                        </b-card-body>

                        <div class="custom-card-footer">
                            <b-button
                                    target="_blank"
                                    rel="noreferrer noopener"
                                      :variant="(card.expiry && card.expiry.trim() !== '') ? 'success' : 'success'"
                                    style="width: 100%;"
                                    size="lg"
                                    :href="card.url"
                                    v-on:click="handleGamesDealsButtonClick"
                                    >{{ (card.expiry && card.expiry.trim() !== '') ? (new Date(card.expiry).getTime() < new Date().getTime() ? 'Claim!' : 'Claim!') : 'Claim!' }} </b-button
                                  >
                        </div>
                    </b-card>
                </b-col>
              </b-row>
            </b-row>
            <div>
            <pagination
            v-model="currentPage"
      :records="totalDealCards"
      :per-page="pageSize"
    ></pagination></div>
            </div>
          </b-tab>
          </b-tabs>
        </b-card>
      </b-col>

  </div>
</template>

<script>
import Vue from "vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import Pagination from "vue-pagination-2";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};

miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}
const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);

export default {
  name: "Roblox",
  mounted() {
    if (this.$ga) {
    const params = this.$route.query.ts ? this.$route.query.guild_id : "";
      this.$ga.event("gamesPage", "open:deals-tab", params);}
  },
  data() {
    return {
      getCopyCommandText: `/games roblox`,
      getCopyToGCommandText: `/games tog`,
      gameCards: [],
      freeGamesCard: [],
      freeDealsCard: [],
      featuredCards: [],
      totalDealCards: 0,
      totalGameCards: 0,
      selectedTab:0,
      fetchedRecents: false,
      fetchedTs:false,
      pageSize: 100,
      currentPage: 1,
    };
  },
  notifications: {
    showCopyCommandSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Command copied to clipboard"
    }
  },
  methods: {

  async fetchDealsWithTs(page){
  this.axios
      .get("/api/v1/deals",{
          params: {
            ts: this.$route.query.ts,
            page: page
          }
        })
      .then(r => {
              this.freeDealsCard = r.data.data.deals;
              this.totalDealCards = r.data.data.total;
              this.featuredCards = r.data.data.featured;
              this.fetchedTs = true;
        this.$Progress.finish();
      });
  },

  async fetchDeals(page){
  this.axios
      .get("/api/v1/deals",{
          params: {
            page: page
          }
        })
      .then(r => {
              this.freeGamesCard = r.data.data.deals;
              this.fetchedRecents = true;
              this.totalGameCards = r.data.data.total;
              this.featuredCards = r.data.data.featured;
      });
  },

  onPageChange(page) {
      if (this.selectedTab == 0){
      this.fetchDeals(page)
      }
      else {
      this.fetchDealsWithTs(page);
      }
    },
    onTabChange(index){
    if (index ==1 && this.fetchedTs == false)
    {
      this.selectedTab = 1;
      this.currentPage = 1;
      this.fetchDealsWithTs(this.currentPage);
    }
    else if (index==0 && this.fetchedRecents == false)
    {
        this.selectedTab = 0;
        this.currentPage = 1;
        this.fetchDeals(this.currentPage);
    }
    },
    handlePlayButtonClick() {
      if (this.$ga) {
        this.$ga.event("now-gg-game-new-tab", "click:button:play-roblox", "");
      }
    },
    handleFeaturedCardButtonClick() {
      if (this.$ga) {
      const params = this.$route.query.ts ? this.$route.query.guild_id : "";
        this.$ga.event("gamesPage", "click:button:play-HorizonWalker", params);
      }
    },
    handleGamesDealsButtonClick() {
      if (this.$ga) {
      const params = this.$route.query.ts ? this.$route.query.guild_id : "";
        this.$ga.event("gamesPage", "click:button:claim-deal", params);
      }
    },
  },
  watch: {
    currentPage(newPage) {
      this.onPageChange(newPage);
    },
  },
  computed: {
  ShowDealsTab(){
    return this.$route.query.ts
    },
    current_guild() {
      return this.$store.state.discord_guilds.find(
        x => x.id == this.$route.params.guild_id
      );
    },
    showFeaturedCard(){

  return this.currentPage == 1;

  },
  },
  created: function() {
    this.$Progress.start();
    if (this.$route.query.ts)
    {    this.selectedTab = 1;
         this.fetchDealsWithTs(1);
    }
    else
    {
      this.selectedTab = 0
      this.fetchDeals(1);
      }
    this.$Progress.finish();
  }
};

</script>

<style src="spinkit/spinkit.min.css"></style>

<style scoped>
div.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 320px;
  height: 10vh;
}
img.item {
  width: 100%;
  max-width: 200px;
}

img.showoff {
  max-width: 100%;
}

.caption {
  display: block;
}

.shartCommand {
  color: #fefefe;
  font-family: poppins;
  font-size: 16px;
  margin: 20px 0 0 0;
  background: #343b41;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.custom-card {
height: 95%;
width: 80%;
  display: flex;
  flex-direction: column;
  border: 3px solid #2C2C29;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent overflow */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.featured-card {
box-shadow: 0 4px 8px rgba(255, 215, 0, 0.4);
}
.featured-button {
  background-color: #ff33b5;
  color: white; /* Optional: Adjust text color for contrast */
  border: none;
  padding: 10px; /* Optional: Add some padding */
  border-radius: 4px; /* Optional: Add rounded corners */
  cursor: pointer;
}

.featured-button:hover {
  background-color: #ff09a5;
}

.custom-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-card-img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.custom-card-body {
  flex: 1;
  padding: 0.1rem;
}

.custom-card-title {
  font-size: 1.25rem;
  height: 3rem;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-card-text {
  font-size: 1rem;
}

.custom-card-footer {
  padding: 0;
  text-align: center;
  margin-top: 8%;
  display: flex;
  justify-content:center;
}

.tog-img-row {
height: 44.2vh;
overflow: hidden;
}

.tog-img-row img {
  height: 44.2vh;
  object-fit: scale-down;
}

.card-container{
height: 98%;
width: 95%;
margin: 0 auto;
}
.deals-container{
height: 70%;
width: 75%;
}
</style>
