<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="/">
        <img
      class="navbar-brand-full d-md-down-none mx-2"
      src="@/../public/img/brand/entirelogo.svg"
      width="89"
      height="25"
      alt="Carlbot logo"
    />
        <img
      v-if="is_vip"
      class="navbar-brand-full d-md-down-none"
      src="@/../public/img/crown.png"
      width="15%"
      height="40%"
      alt="Carlbot logo"
    />
        <img
          class="navbar-brand-minimized"
          src="@/../public/img/brand/googleturtle.svg"
          width="30"
          height="30"
          alt="Carlbot logo"
        />
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <b-navbar-nav class="d-md-down-none nav-title">
        <b-nav-item class="px-2" href="https://docs.carl.gg/">
          <i class="fas fa-book mr-1"></i>Docs
        </b-nav-item>
        <b-nav-item
          class="px-2"
          href="https://discord.com/oauth2/authorize?&client_id=235148962103951360&scope=applications.commands+bot&permissions=2088234230&response_type=code&redirect_uri=https://carl.gg/api/server_auth"
          exact
        >
          <i class="fas fa-plus mr-1"></i>Invite
        </b-nav-item>
        <b-nav-item class="px-2" href="https://discord.gg/S2ZkBTnd8X">
          <i class="fab fa-discord mr-1"></i>Discord
        </b-nav-item>
        <b-nav-item class="px-2" href="https://www.patreon.com/carlbot">
           <img :src="imageUrl" style="width: 12px; height: auto; margin-bottom: 3px"/> Premium
        </b-nav-item>
        <b-nav-item class="px-2" to="/status">
          <i class="fas fa-heartbeat mr-1"></i>Status
        </b-nav-item>
        <b-nav-item class="px-2" to="/gaming/deals">
            <i class="fas fa-gamepad"></i> Games
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="d-lg-none nav-title nav-icons">
        <b-nav-item to="/">
          <i class="fas fa-home"></i>
        </b-nav-item>
        <b-nav-item href="https://docs.carl.gg/">
          <i class="fas fa-book"></i>
        </b-nav-item>
        <b-nav-item
          href="https://discord.com/oauth2/authorize?&client_id=235148962103951360&scope=applications.commands+bot&permissions=2088234230&response_type=code&redirect_uri=https://carl.gg/api/server_auth"
          exact
          class="d-sm-down-none"
        >
          <i class="fas fa-plus"></i>
        </b-nav-item>
        <b-nav-item href="https://discord.gg/S2ZkBTnd8X" class="d-sm-down-none">
          <i class="fab fa-discord"></i>
        </b-nav-item>
        <b-nav-item href="https://www.patreon.com/carlbot">
          <i class="fab fa-patreon"></i>
        </b-nav-item>
        <b-nav-item to="/status">
          <i class="fas fa-heartbeat"></i>
        </b-nav-item>
        <b-nav-item to="/gaming/deals">
            <i class="fas fa-gamepad"></i>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto d-none d-sm-inline-block">
        <b-row v-if="logged_in">
          <b-col cols="12">
            <DefaultHeaderDropdownServer />
          </b-col>
        </b-row>
      </b-navbar-nav>
      <b-navbar-nav class="ml-4">
        <b-row v-if="logged_in">
          <!-- <b-col style="width:300px">
            <DefaultHeaderDropdownServer/>
          </b-col>-->
          <b-col cols="12">
            <DefaultHeaderDropdownAccnt />
          </b-col>
        </b-row>
        <div v-else class="mr-2">
          <b-button
            style="
              background-color: #3a4149;
              border-color: #eee;
              border-width: 2px;
            "
            v-on:click="login"
            class="px-4 btn-pill dark"
            >Login</b-button
          >
        </div>
      </b-navbar-nav>
      <!--<AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <!-- <SidebarForm/> -->
        <SidebarNav :navItems="nav"></SidebarNav>
        <!-- <SidebarFooter/> -->
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <div class="container-fluid" style="padding: 0 15px">
          <br v-if="isGameHeadingEnabled" />
          <p class="gameHeading" v-if="isGameHeadingEnabled">
            <span class="badge badge-custom-roblox align-middle">New</span>
            You can now play games on Carl. Explore this new feature now!
          </p>
          <br />
          <vue-progress-bar></vue-progress-bar>
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import nav from "@/_nav";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb
} from "@coreui/vue";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
import DefaultHeaderDropdownServer from "./DefaultHeaderDropdownServer";

export default {
  name: "DefaultContainer",
  components: {
    AppHeader,
    AppSidebar,
    DefaultHeaderDropdownAccnt,
    DefaultHeaderDropdownServer,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data() {
    return {
      discord_user: {},
      is_vip: false,
      server_id: {},
      imageUrl: 'https://i.imgur.com/OOh0shV.png',
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (this.server_id !== to.params.guild_id) {
      this.server_id = to.params.guild_id;
      this.fetchServerData();
      next();
    } else {
      next();
    }
  },
  created() {
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/meta")
      .then((r) => {
        this.is_vip = r.data.is_vip;
      });
  },
  methods: {
    login() {
      this.$router.push("/api/login");
    },
    updateAuthInfo(e) {
      if (e.data.discriminator != undefined) {
        this.$store.commit("login", e.data);
      }
      window.removeEventListener("message", this.updateAuthInfo);

      this.$router.push("/servers");
    },
    fetchServerData(){
      this.axios
        .get(`/api/v1/servers/${this.server_id}/meta`)
        .then((response) => {
          this.prefixes = response.data.prefixes;
          this.is_vip = response.data.is_vip;
          this.expiryDate = new Date(response.data.expires).getTime();
          this.is_piggy = response.data.is_piggy;
        })
  }
},
  computed: {
    nav() {
      const viewerCountryCode =
        this.$store.state &&
        this.$store.state.user_geo &&
        this.$store.state.user_geo.viewerCountryCode;

      return (
        nav.items &&
        nav.items.filter(item => {
          if (item.url === "games") {
            return viewerCountryCode === "US";
          } else {
            return true;
          }
        })
      );
    },
    isGameHeadingEnabled() {
      const viewerCountryCode =
        this.$store.state.user_geo &&
        this.$store.state.user_geo.viewerCountryCode;
      return viewerCountryCode === "US";
    },
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    },
    logged_in() {
      return Object.keys(this.$store.state.discord_user).length !== 0;
    },
    current_guild() {
      return this.$store.state.discord_guilds.find(
        x => x.id == this.$route.params.guild_id
      );
    }
  }
};
</script>
<style src="spinkit/spinkit.min.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect--active {
  z-index: 3 !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  color: #eee !important;
}
.multiselect__input,
.multiselect__single {
  background: #515b65 !important;
  color: #fff !important;
  padding-left: 0px !important;
  padding-top: 2px !important;
  margin-bottom: 11px !important;
}
.multiselect__input::placeholder {
  color: #ddd !important;
  font-size: 14px;
}
.multiselect__tags {
  border: 1px solid #232823 !important;
  background: #515b65 !important;
}
.multiselect__tag {
  background: #2f353a !important;
  border-width: 1px !important;
  border-style: solid;
  border-radius: 500px;
  padding: 5px 10px 4px 26px !important;
  cursor: default;
}

.multiselect__option .multiselect__option--group {
  background-color: #2e343a !important;
  color: #eee;
}

.channelpicker__tag {
  border-radius: 6px !important;
  border-color: #eeeb !important;
}

.multiselect__tag-icon {
  left: -5px !important;
}

.multiselect__tag:hover .multiselect__tag-icon:after {
  content: "\D7";
  color: #fff;
  font-size: 14px;
  margin: -3.5px 0px 10px -4px;
  position: absolute;
}

.multiselect__tag-icon:after {
  content: "\D7";
  color: #fff0;
  font-size: 12px;
  margin: -3.5px 0px 10px -3.5px;
  position: absolute;
}

.multiselect__select:before {
  color: #2f353a !important;
  border-color: #2f353a transparent transparent !important;
}
.defaultcursor {
  cursor: default;
}
.multiselect__placeholder {
  color: #adadad !important;
}
.multiselect--active .multiselect__placeholder {
  color: #eee !important;
}
.multiselect__content-wrapper {
  background: #3a4149 !important;
  border: 1px solid #2f353a !important;
}
.multiselect--above .multiselect__content-wrapper {
  border-top: 1px solid #2f353a !important;
}
.multiselect__option--selected {
  background: #515b65 !important;
}

.fakediscord {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 4px 10px 11px 9px;
  cursor: pointer;
}

.gameHeading {
  background: #4d53bd;
  padding: 4px;
  border-radius: 5px;
  margin: 0;
}

.channelpicker_icon {
  background-color: #00000000 !important;
  border-radius: 0;
  border-width: 1px;
  border-style: none solid none none;
  border-color: #eeeb;
  width: 23px;
  height: 25px;
  margin: -1px -4px -20px 4px;
  padding-top: 5px;
}

.channelpicker_icon:after {
  content: "\D7";
  color: #999;
  font-size: 14px;
  margin: -3.5px 0px 10px -4px;
  position: absolute;
}

.multiselect__option--group {
  background-color: #2e343a !important;
  color: #eee !important;
}

.multiselect__option.multiselect__option--selected
  .option__desc
  .option__title {
  color: #bbb !important;
}

.multiselect__spinner {
  background: #515b65 !important;
}

.vc-sketch {
  background-color: #2f353a !important;
  position: absolute !important;
  z-index: 200;
}
.vc-sketch:active {
  user-select: none;
}

.vc-input__input {
  background-color: #515b65 !important;
  color: #eee !important;
  box-shadow: inset 0 0 0 1px #111 !important;
}
.vc-input__label {
  color: #eee !important;
}
.vc-sketch-alpha-wrap {
  display: none;
}
</style>
<style scoped>
.nav-title .nav-link {
  font-family: "roboto";
  color: #fff;
}

.nav-icons i {
  font-size: 1.075rem;
}
</style>
