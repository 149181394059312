//import '@babel/polyfill'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
//import './polyfill'
// import cssVars from 'css-vars-ponyfill'
//import '@babel/polyfill'
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import App from "./App";
import router from "./router/index";
import axios from "axios";
import VueAxios from "vue-axios";
import VueClipboard from "vue-clipboard2";
import store from "./store";
import VueAnalytics from "vue-analytics";
import VueNotifications from "vue-notifications";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRss,
  faPlus,
  faCog,
  faTimes,
  faCopy,
  faClock,
  faQuestionCircle,
  faCheckCircle,
  faHashtag,
  faUserTag,
  faPiggyBank,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faTwitch } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueProgressBar from "vue-progressbar";
import Pagination from "vue-pagination-2";

library.add(
  faRss,
  faPlus,
  faDiscord,
  faCog,
  faTimes,
  faCopy,
  faClock,
  faQuestionCircle,
  faCheckCircle,
  faUserTag,
  faTwitch,
  faHashtag,
  faPiggyBank,
  faChevronDown
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("pagination", Pagination);

const options = {
  color: "#20a8d8",
  failedColor: "#874b4b",
  thickness: "2px",
  transition: {
    speed: "0.6s",
    opacity: "0.5s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
};

Vue.use(VueProgressBar, options);
// todo
// cssVars()

Vue.use(require('vue-cookies'))
Vue.use(VueToast);
Vue.use(VueClipboard);
Vue.use(BootstrapVue);
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_DOMAIN;
axios.interceptors.request.use((config) => {
    config.headers["X-CSRF-TOKEN"] = Vue.$cookies.get("csrf_cookie");
    return config;
  }, (error) => {
    return Promise.reject(error);
});
axios.interceptors.response.use(null, function(error) {
  if (error.response.status === 401) {
    store.commit("logout");
    Vue.$cookies.set('session','',0);
    router.push("/");
  } else if (error.response.status === 403) {
   Vue.$toast.open({message: error.response.data.message,
      position: 'top-right',
      type: 'error',});
  } else if (error.response.status === 500) {
    router.push("/errors/500");
  } else if (error.response.status === 404) {
    router.push("/404");
  }
  return Promise.reject(error);
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Carl-bot Dashboard";
  next();
});
// axios.defaults.headers.put['Content-Type'] = 'application/json';
// axios.defaults.headers.patch['Content-Type'] = 'application/json';
Vue.use(VueAxios, axios);
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_PROJECT_ID,
  router
});

Vue.mixin({
  methods: {
    handleOnlyNumberkeyInput(event) {
      const key = event.key;

      if (key === "-" || key === "+" || key === "e" || key === "E") {
        event.preventDefault();
      }
    },
    handleNonDecimalInput(event) {
      const key = event.key;

      if (key === "-" || key === "+" || key === "e" || key === "E" || key === ".") {
        event.preventDefault();
      }
  }
}
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  template: "<App/>",
  components: {
    App
  }
});
