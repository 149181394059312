import Vue from "vue";
import Router from "vue-router";

// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");
const DefaultContainerNoSidenav = () =>
  import("@/containers/DefaultContainerNoSidenav");
const OnboardingContainer = () => import("@/containers/OnboardingContainer");
const goaway = () => import("@/containers/goaway");

// Containers
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Premium = () => import("@/views/oc/Premium");
// const Page500 = () => import('@/views/pages/Page500')
// Views - OC
const StarBoard = () => import("@/views/oc/StarBoard");
const BotSettings = () => import("@/views/oc/BotSettings");
const Logging = () => import("@/views/oc/Logging");
const Moderation = () => import("@/views/oc/Moderation");
const Tags = () => import("@/views/oc/Tags");
const Triggers = () => import("@/views/oc/Triggers");
const Welcome = () => import("@/views/oc/Welcome");
const Autoroles = () => import("@/views/oc/Autoroles");
const Embeds = () => import("@/views/oc/Embeds");
const AutoFeeds = () => import("@/views/oc/AutoFeeds");
const ServerPicker = () => import("@/views/oc/ServerPicker");
const Automod = () => import("@/views/oc/Automod");
const Weblogs = () => import("@/views/oc/Weblogs");
const Commands = () => import("@/views/oc/Commands");
const Suggestions = () => import("@/views/oc/Suggestions");
const ReactionRoles = () => import("@/views/oc/ReactionRoles");
const VoiceLink = () => import("@/views/oc/Voicelink");

const SharedTag = () => import("@/views/oc/SharedTag");

const About = () => import("@/views/oc/About");

const Levels = () => import("@/views/oc/Levels");
const Twitch = () => import("@/views/oc/Twitch");
const Youtube = () => import("@/views/oc/Youtube")
const CarlGaming = () => import("@/views/oc/CarlGaming")

const Status = () => import("@/views/oc/Status");

const Games = () => import("@/views/oc/Roblox");
const TOS = () => import("@/views/oc/monkatos");

const OnboardingLanding = () => import("@/views/oc/OnboardingLanding");
const OnboardingGeneral = () => import("@/views/oc/OnboardingGeneral");
const OnboardingWelcome = () => import("@/views/oc/OnboardingWelcome");
const OnboardingModeration = () => import("@/views/oc/OnboardingModeration");
const OnboardingAutomod = () => import("@/views/oc/OnboardingAutomod");
const InstaplayapiGames = () => import("@/views/oc/InstaplayGames");
const OnboardingReactionRoles = () =>
  import("@/views/oc/OnboardingReactionRoles");
const OnboardingFinal = () => import("@/views/oc/OnboardingFinal");

Vue.use(Router);

export default new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      component: DefaultContainerNoSidenav,
      children: [
        {
          path: "",
          name: "goaway",
          component: goaway
        },
        {
          path: "about",
          name: "about",
          component: About
        },
        {
          path: "status",
          name: "Status",
          component: Status
        },
        {
          path: "gaming/deals",
          name: "Games",
          component: Games
        },
        {
          path: "servers",
          name: "Servers",
          component: ServerPicker,
          meta: {
            title: "Server picker"
          }
        },
        {
          path: '/game-availability-check',
          name: 'Alert',
          beforeEnter: (to, from, next) => {
            alert('Sorry this game is unavailable for you');
            next('/');
          },
        },
        {
          path: "terms-of-use",
          name: "Terms of Use",
          component: TOS,
          meta: {
            title: "Terms of Use"
          }
        },
        {
          path: "premium",
          name: "Premium",
          component: Premium,
          meta: {
            title: "Premium"
          }
        },
        {
          path: "t/:tag_id(\\d+)",
          name: "SharedTag",
          component: SharedTag,
          meta: {
            title: "Import tag"
          }
        }
      ]
    },
    {
      path: "*",
      component: Page404
    },
    {
      path: "/errors/500",
      component: Page500
    },
    {
      path: "/dashboard/:guild_id(\\d+)",
      name: "Home",
      component: DefaultContainer,
      redirect: "/dashboard/:guild_id(\\d+)/botsettings",
      meta: {
        progress: {
          func: [
            { call: "color", modifier: "temp", argument: "#ffb000" },
            { call: "fail", modifier: "temp", argument: "#6e0000" },
            { call: "location", modifier: "temp", argument: "top" },
            {
              call: "transition",
              modifier: "temp",
              argument: { speed: "1.5s", opacity: "0.6s", termination: 400 }
            }
          ]
        }
      },
      children: [
        {
          path: "botsettings",
          name: "BotSettings",
          component: BotSettings
        },
        {
          path: "starboard",
          name: "StarBoard",
          component: StarBoard
        },
        {
          path: "logging",
          name: "Logging",
          component: Logging
        },
        {
          path: "moderation",
          name: "Moderation",
          component: Moderation
        },
        {
          path: "tags",
          name: "Tags",
          component: Tags
        },
        {
          path: "triggers",
          name: "Triggers",
          component: Triggers
        },
        {
          path: "welcome",
          name: "Welcome",
          component: Welcome
        },
        {
          path: "autoroles",
          name: "Autoroles",
          component: Autoroles
        },
        {
          path: "autofeeds",
          name: "AutoFeeds",
          component: AutoFeeds
        },
        {
          path: "embeds",
          name: "Embeds",
          component: Embeds
        },
        {
          path: "automod",
          name: "Automod",
          component: Automod
        },
        {
          path: "weblogs",
          name: "Weblogs",
          component: Weblogs
        },
        {
          path: "commands",
          name: "Commands",
          component: Commands
        },
        {
          path: "suggestions",
          name: "Suggestions",
          component: Suggestions
        },
        {
          path: "reactionroles",
          name: "Reaction Roles",
          component: ReactionRoles
        },
        {
          path: "voicelink",
          name: "Voice link",
          component: VoiceLink
        },
        {
          path: "levels",
          name: "Levels",
          component: Levels
        },
        {
          path: "twitch",
          name: "Twitch",
          component: Twitch
        },
        {
          path: "youtube",
          name: "Youtube",
          component: Youtube
        },
        {
          path: "carl-gaming",
          name: "Carl Gaming",
          component: CarlGaming
        },
        {
          path: "Games",
          name: "Free Games",
          component: InstaplayapiGames
        }
      ]
    },
    {
      path: "/onboarding/:guild_id(\\d+)",
      name: "Onboarding Home",
      component: OnboardingContainer,
      redirect: "/onboarding/:guild_id(\\d+)/landing",
      meta: {
        progress: {
          func: [
            { call: "color", modifier: "temp", argument: "#ffb000" },
            { call: "fail", modifier: "temp", argument: "#6e0000" },
            { call: "location", modifier: "temp", argument: "top" },
            {
              call: "transition",
              modifier: "temp",
              argument: { speed: "1.5s", opacity: "0.6s", termination: 400 }
            }
          ]
        }
      },
      children: [
        {
          path: "general",
          name: "General",
          component: OnboardingGeneral
        },
        {
          path: "welcome",
          name: "Welcome",
          component: OnboardingWelcome
        },
        {
          path: "moderation",
          name: "Moderation",
          component: OnboardingModeration
        },
        {
          path: "automod",
          name: "Automod",
          component: OnboardingAutomod
        },
        {
          path: "reactionroles",
          name: "ReactionRoles",
          component: OnboardingReactionRoles
        },
        {
          path: "landing",
          name: "Landing",
          component: OnboardingLanding
        },
        {
          path: "final",
          name: "Final",
          component: OnboardingFinal
        }
      ]
    }
  ]
});
