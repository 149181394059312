export default {
  items: [
    {
      title: true,
      name: "Settings",
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: "Bot settings",
      url: "./botsettings",
      icon: "icon-settings"
    },
    {
      name: "Commands",
      url: "./commands",
      icon: "icon-wrench"
    },
    {
      name: "Weblogs",
      url: "./weblogs",
      icon: "icon-list"
    },
    {
      title: true,
      name: "Moderation",
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: "Automod",
      url: "./automod",
      icon: "icon-shield"
    },
    {
      name: "Moderation",
      url: "./moderation",
      icon: "fa fa-gavel"
    },
    {
      name: "Logging",
      url: "./logging",
      icon: "icon-notebook"
    },
    {
      name: "Autoroles",
      url: "./autoroles",
      icon: "icon-user-follow"
    },
    {
      title: true,
      name: "Carl-Gaming",
      class: "",
      wrapper: {
        element: "",
        attributes: {},
      },
    },
    {
      name: "Game Alert",
      url: "./carl-gaming",
      icon: "fas fa-gamepad",
      badge: {
        variant: "custom-roblox",
        text: "New"
      },
    },
    {
      name: "Games",
      url: "games",
      icon: "fa fa-gamepad",
      badge: {
        variant: "custom-roblox",
        text: "New"
      }
    },
    {
      title: true,
      name: "Feeds",
      class: "",
      wrapper: {
        element: "",
        attributes: {},
      },
    },
    {
      name: "Twitch",
      url: "twitch",
      icon: "fab fa-twitch"
    },
    {
      name: "Youtube",
      url: "youtube",
      icon: "fab fa-youtube"
    },
    {
      title: true,
      name: "Utility",
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: "Welcome",
      url: "./welcome",
      icon: "icon-login"
    },
    {
      name: "Reaction roles",
      url: "./reactionroles",
      icon: "far fa-kiss-wink-heart"
    },
    {
      name: "Starboard",
      url: "./starboard",
      icon: "icon-star"
    },
    {
      name: "Levels ",
      url: "./levels",
      icon: "icon-chart",
      badge: {
        variant: "primary",
        text: "Premium"
      }
    },
    {
      name: "Tags",
      url: "./tags",
      icon: "icon-tag"
    },
    {
      name: "Triggers",
      url: "./triggers",
      icon: "icon-speech"
    },
    {
      name: "Embeds",
      url: "./embeds",
      icon: "icon-picture"
    },
    {
      name: "Autofeeds",
      url: "./autofeeds",
      icon: "icon-feed"
    },
    {
      name: "Suggestions",
      url: "./suggestions",
      icon: "icon-bulb"
    },
    {
      name: "Voice link",
      url: "./voicelink",
      icon: "icon-link",
      badge: {
        variant: "primary",
        text: "Premium"
      }
    },
  ]
};
